import React from 'react'
const SvgShowPass = ({ type, className }) => {
	switch (type) {
		case 'eye':
			return (
				<svg
					className={className}
					width='17'
					height='13'
					viewBox='0 0 17 13'
					fill='#BBBBBB'
					xmlns='http://www.w3.org/2000/svg'>
					<path d='M4.51562 2.67969C5.57031 1.88281 6.88281 1.25 8.5 1.25C10.375 1.25 11.8984 2.11719 13 3.14844C14.1016 4.15625 14.8281 5.375 15.1797 6.21875C15.25 6.40625 15.25 6.61719 15.1797 6.80469C14.875 7.55469 14.2422 8.63281 13.3047 9.57031L15.7656 11.5156C16.0234 11.7031 16.0703 12.0547 15.8594 12.2891C15.6719 12.5469 15.3203 12.5938 15.0859 12.3828L1.21094 1.50781C0.953125 1.32031 0.90625 0.96875 1.11719 0.734375C1.30469 0.476562 1.65625 0.429688 1.89062 0.640625L4.51562 2.67969ZM5.42969 3.40625L6.50781 4.25C7.04688 3.78125 7.72656 3.5 8.5 3.5C10.1406 3.5 11.5 4.85938 11.5 6.5C11.5 7.01562 11.3594 7.48438 11.1484 7.88281L12.4141 8.86719C13.2109 8.07031 13.7734 7.15625 14.0781 6.5C13.75 5.79688 13.1406 4.8125 12.2266 3.96875C11.2656 3.07812 10.0234 2.375 8.5 2.375C7.30469 2.375 6.27344 2.79688 5.42969 3.40625ZM10.2344 7.17969C10.3281 6.96875 10.375 6.73438 10.375 6.5C10.375 5.46875 9.53125 4.625 8.5 4.625C8.47656 4.625 8.45312 4.625 8.42969 4.625C8.47656 4.76562 8.5 4.88281 8.5 5C8.5 5.25781 8.42969 5.46875 8.33594 5.67969L10.2344 7.17969ZM10.4688 10.2266L11.4531 11C10.5859 11.4688 9.60156 11.75 8.5 11.75C6.60156 11.75 5.07812 10.9062 3.97656 9.875C2.875 8.84375 2.14844 7.625 1.79688 6.80469C1.72656 6.61719 1.72656 6.40625 1.79688 6.21875C2.03125 5.67969 2.40625 5 2.94531 4.29688L3.8125 5C3.39062 5.53906 3.08594 6.07812 2.89844 6.5C3.22656 7.20312 3.83594 8.21094 4.75 9.05469C5.71094 9.94531 6.95312 10.625 8.5 10.625C9.20312 10.625 9.85938 10.4844 10.4688 10.2266ZM5.5 6.5C5.5 6.45312 5.5 6.38281 5.5 6.3125L6.8125 7.34375C7.04688 7.83594 7.51562 8.21094 8.07812 8.32812L9.39062 9.38281C9.10938 9.45312 8.80469 9.5 8.47656 9.5C6.83594 9.5 5.47656 8.16406 5.47656 6.5H5.5Z' />
				</svg>
			)
		case 'eye-filled':
			return (
				<svg
					className={className}
					width='14'
					height='11'
					viewBox='0 0 14 11'
					fill='#4F33A3'
					xmlns='http://www.w3.org/2000/svg'>
					<path d='M4.25 5.5C4.25 3.85938 5.58594 2.5 7.25 2.5C8.89062 2.5 10.25 3.85938 10.25 5.5C10.25 7.16406 8.89062 8.5 7.25 8.5C5.58594 8.5 4.25 7.16406 4.25 5.5ZM7.25 7.375C8.28125 7.375 9.125 6.55469 9.125 5.5C9.125 4.46875 8.28125 3.625 7.25 3.625C7.22656 3.625 7.20312 3.625 7.17969 3.625C7.22656 3.76562 7.25 3.88281 7.25 4C7.25 4.84375 6.57031 5.5 5.75 5.5C5.60938 5.5 5.49219 5.5 5.375 5.45312C5.375 5.47656 5.375 5.5 5.375 5.5C5.375 6.55469 6.19531 7.375 7.25 7.375ZM2.72656 2.14844C3.82812 1.11719 5.35156 0.25 7.25 0.25C9.125 0.25 10.6484 1.11719 11.75 2.14844C12.8516 3.15625 13.5781 4.375 13.9297 5.21875C14 5.40625 14 5.61719 13.9297 5.80469C13.5781 6.625 12.8516 7.84375 11.75 8.875C10.6484 9.90625 9.125 10.75 7.25 10.75C5.35156 10.75 3.82812 9.90625 2.72656 8.875C1.625 7.84375 0.898438 6.625 0.546875 5.80469C0.476562 5.61719 0.476562 5.40625 0.546875 5.21875C0.898438 4.375 1.625 3.15625 2.72656 2.14844ZM7.25 1.375C5.70312 1.375 4.46094 2.07812 3.5 2.96875C2.58594 3.8125 1.97656 4.79688 1.64844 5.5C1.97656 6.20312 2.58594 7.21094 3.5 8.05469C4.46094 8.94531 5.70312 9.625 7.25 9.625C8.77344 9.625 10.0156 8.94531 10.9766 8.05469C11.8906 7.21094 12.5 6.20312 12.8281 5.5C12.5 4.79688 11.8906 3.8125 10.9766 2.96875C10.0156 2.07812 8.77344 1.375 7.25 1.375Z' />
				</svg>
			)
	}
}
export default SvgShowPass
